* {
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Ubuntu', sans-serif;
  line-height: 1.4;
  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
}

p {
  margin: 0.75rem 0;
}

/* Utility Classes */
.container {
  max-width: 1100px;
  margin: auto;
  padding: 0 2rem;
  overflow: hidden;
}

.text-center {
  text-align:center;
}

.text-primary {
  color: #93cb52;
}

.bg-light {
  background: #f4f4f4;
  color: #333;
}

.bg-dark {
  background: #333;
  color: #fff;
}

.bg-primary {
  background: #93cb52;
  color: #fff;
}

.l-heading {
  font-size: 4rem;
  margin-bottom: 0.75rem;
  line-height: 1.1;
}

.m-heading {
  font-size: 2rem;
  margin-bottom: 0.75rem;
  line-height: 1.1;
}

.lead {
  font-size: 1.3rem;
  margin-bottom: 2rem;
}

.py-1 { padding: 1.5rem 0; }
.py-2 { padding: 2rem 0; }
.py-3 { padding: 3rem 0; }

.p-1 { padding: 1.5rem; }
.p-2 { padding: 2rem; }
.p-3 { padding: 3rem; }

.btn {
  display: inline-block;
  color: #fff;
  background: #93cb52;
  padding: 0.5rem 2rem;
  border: none;
  border-radius: 5px;
  -webkit-appearance: none;
}

.btn:hover {
  background: #7ab436;
  cursor: pointer;
}

.btn-dark {
  background: #333;
  color: #fff;
}

.list {
  margin: 0.5rem 0;
  list-style: none;
}

.list li {
  padding: 0.5rem 0;
  border-bottom: #444 dotted 1px;
}
