/* Navbar */
#navbar {
  display: flex;
  position: sticky;
  top:0;
  background: #333;
  color: #fff;
  justify-content: space-between;
  z-index: 1;
  padding: 1rem;
}

#navbar .logo .text-primary {
  font-family: 'Unica One', cursive;
}

#navbar ul {
  display: flex;
  align-items: center;
  list-style: none;
}

#navbar ul li a {
  color: #fff;
  padding: 0.75rem;
  margin: 0 0.25rem;
}

#navbar ul li a:hover {
  background: #93cb52;
  border-radius: 5px;
}

/* Showcase */
#showcase {
  background: #333 url('./img/harry-cao-1393533-unsplash.jpg') no-repeat center center/cover;
  height: 100vh;
  min-height: 500px;
  color: #fff;
}

#showcase .showcase-content {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0 2rem;
  /* Overlay */
  position: absolute;
  top: 65px;
  left:0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.4);
}

/* Section: Products */
#products {
  display: flex;
  min-height: 400px;
}

#products div {
  flex: 1;
}

#products h3 {
  margin-top: 3rem;
}

#products .products-img {
  background: url('./img/christian-fregnan-711971-unsplash.jpg') no-repeat center center/cover;
}

/* Section: Clients */
#clients .items {
  display: flex;
}

#clients .items img {
  display: block;
  margin: auto;
  width: 60%;
}

/* Section: Contact */
#contact {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #93cb52;
}

#contact .contact-form {
  width: 50%;
}

#contact .contact-text {
  vertical-align: center;
}

#contact .contact-form .form-group {
  margin: 0.75rem;
}

#contact .contact-form label {
  display: block;
}

#contact .contact-form input,
#contact .contact-form textarea {
  width: 100%;
  padding: 0.5rem;
  font-size: 1rem;
}

#contact .contact-form .btn {
  padding: 1rem 0;
  width: 99%;
  font-size: 2rem;
  margin-left: 0.75rem;
}

/* Section: Company Info */
#company-info {
  display: flex;
  text-align: right;
}

#company-info div {
  flex: 1;
  padding-right: 10%;
}

#company-info .email {
  margin-top: 2rem;
}

#company-info .email a {
  color: #ccc;
  text-decoration: underline;
}
